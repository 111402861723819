


























import { ResponseListMasterType } from "@interface/master.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { IOption } from "@interface/common.interface";
import Vue from "vue";
import { masterTypeService } from "@service/master-type.service";
export default Vue.extend({
  name: "CSelectReturnFrom",
  props: {
    value: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dtOpt: [] as IOption[],
      loading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getListMasterType(params: RequestQueryParamsModel): Promise<ResponseListMasterType[]> {
      return masterTypeService.listMaster(params);
    },
    async getList(): Promise<void> {
      try {
        this.loading = true;
        const res = await this.getListMasterType({name: "SALES_RETURN_FORM"});
        this.dtOpt = res.map(x => { return { key: x.value, value: x.value };}).filter(x => x.value !== "Invoice");
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    onSelect(e: string): void {
      this.$emit("input", e);
      this.$emit("on-select", e);
    }
  },
});
